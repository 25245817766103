.app-grid {
  display: grid;
  grid-template-areas:
    " htop "
    " header_main "
    " app-content "
    " footer ";
  grid-template-rows: 80px minmax(auto, 300px) auto 200px;
  grid-template-columns: 1fr;
}

.iah_header {
  grid-area: header_main;
  display: grid;
  grid-template-areas:
    " . .         iah_logo  iah_logo  iah_logo  . "
    " . iah_title iah_title iah_title iah_title . ";
  grid-template-rows: minmax(120px, 160px) minmax(60px, 80px);
  grid-template-columns: 40px repeat(4, 1fr) 40px;
  justify-items: center;
  align-content: end;
  column-gap: 6px;
  padding: 10px 0px 0px 0px;
}

.app-content {
  grid-area: app-content;
}

.header_main {
  grid-area: header_main;
  background: var(--iah-light);
}
.header_left {
  display: none;
  grid-area: header_left;
  background: var(--iah-light);
}
.header_right {
  display: none;
  grid-area: header_right;
  background: var(--iah-light);
}

.iah_logo {
  grid-area: iah_logo;
  height: 100%;
  width: 100%;
}

.iah_title {
  grid-area: iah_title;
  height: 100%;
  width: 100%;
}

.iah_footer {
  grid-area: footer;
  display: grid;
  justify-items: center;
  align-items: start;
  background: var(--iah-grey-dark);
}

.iah_footer_text {
  margin: 100px;
  font-family: "Raleway";
  font-weight: 300;
  font-size: 1.5em;
  color: var(--iah-light);
}

.htop {
  grid-area: htop;
  background: var(--iah-grey-dark);
}

@media (min-width: 780px) {
  .header_left,
  .header_right {
    display: inline;
  }

  .iah_header {
    grid-template-areas:
      " . iah_logo  .          .  "
      " . iah_logo  iah_title  .  "
      " . iah_logo  .          .  ";
    grid-template-rows: 40px 100px 20px;
    grid-template-columns:
      20px
      minmax(140px, 180px)
      minmax(240px, 580px)
      minmax(60px, auto);
    justify-items: start;
    align-items: end;
  }

  .app-grid {
    grid-template-areas:
      " htop        htop         htop        htop"
      " header_left header_main  header_main header_right "
      " app-content app-content  app-content  app-content "
      " footer      footer       footer      footer ";
    grid-template-rows: 80px minmax(auto, 200px) minmax(0, auto) 600px;
    grid-template-columns:
      minmax(20px, 1fr)
      200px
      minmax(200px, 1200px)
      minmax(20px, 1fr);
  }
}
